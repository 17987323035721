@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import "../css/variables.scss";

body {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 16px;
    line-height: normal;
}

.img-fluid{
    width: 100%!important;
    height: 100%!important;
    object-fit: contain!important;
    overflow: hidden !important;
}


.img-fluid-1{
    float: right;
    width: 80%!important;
    height: 80%!important;
    object-fit: contain!important;
    overflow: hidden !important;
}

.img-fluid-2{
    // float: right;
    width: 60%!important;
    height: 80%!important;
    object-fit: contain!important;
    overflow: hidden !important;
}

.img-fluid-3{
    height: 100% !important;
    object-fit: cover !important;
    overflow: hidden !important;
    width: auto !important;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.heading-1 {
    font-size: 29px;
    font-weight: 500;
}

.font-bold {
    font-weight: $font-bold;
}

.font-medium {
    font-weight: $font-medium !important;
}

.font-light {
    font-weight: $font-light;
}

.font-regular {
    font-weight: $font-regular;
}

a {
    text-decoration: none;
    transition: 0.4s;

    &:hover {
        color: #0A3361;
    }
}

.text-brand {
    color: $color-primary;
}
.pointer-cursor{
    cursor: pointer;
}
.btn-brand-1 {
    background-color: $color-primary;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    font-weight: 500;
    height: 42px;
    border: 0;
    padding: 7px 27px;
    line-height: 25px;

    &:hover {
        background-color: $color-secondary;
        color: #fff;
    }
}

// left menu css
.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;

    .header-nav {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

// Header

.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);

    .header-nav {
        .navbar {
            padding: 0;
        }
    }

    .header-nav {
        background-color: #fff;

        .v-devider {
            display: inline-block;
            width: 1px;
            background: #525252;
            height: 20px;
            margin: 0 15px;
        }
    }

}

.site-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
    background: #083060;
    padding: 15px;
    text-align: center;

    .copyright {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: $font-regular;
        line-height: normal;
    }
}

// App Body 

.app-body {
    background: #FFF;
}

.top-empty-space {
    padding-top: 120px;
}

.progress {
    background: #F3F3F3;
    height: 9px;
    width: 100%;

    .progress-bar {
        border-radius: 10px;
        background: #B21840;
    }
}

.blue-card {
    background: #09284f !important;
}

.light-blue-card, .blue-card {
    border-radius: 20px;
    background: #F5FAFF;
    border: 1px solid #F3FAF3;
    filter: blur(0px);
    padding: 40px;

    h1 {
        color: #000;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: $font-semibold;
        line-height: normal;
        margin-bottom: 0;
    }

    p {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: $font-regular;
        line-height: 24px;
        padding: 15px 0;
    }

    a.link-btn {
        border-radius: 10px;
        border: 1px solid #CBCBCB;
        color: #0A3361;
        background: #FFF;
        height: 50px;
        padding: 14px;
        width: 100%;
        display: block;
    }

    a.link-btn:hover {
        color: #B21840;
        border-radius: 10px;
        border: 1px solid #B21840;
        background: #FFF;
        box-shadow: 0px 4px 20px 0px rgba(10, 51, 97, 0.10);
    }

    .btn-group {
        button.left-curve-btn {
            border-radius: 25.5px 0px 0px 25.5px;
            background: linear-gradient(90deg, rgba(22, 49, 98, 1) 0%, rgba(42, 45, 93, 1) 100%);
        }

        button.right-curve-btn {
            border-radius: 0px 25.5px 25.5px 0px;
            background: linear-gradient(90deg, rgba(142, 27, 72, 1) 0%, rgba(169, 22, 66, 1) 100%);
        }

        button.middle-btn2 {
            background: linear-gradient(90deg, rgba(48, 44, 91, 1) 0%, rgba(74, 40, 86, 1) 100%);
        }

        button.middle-btn3 {
            background: linear-gradient(90deg, rgba(78, 39, 85, 1) 0%, rgba(105, 35, 80, 1) 100%);
        }

        button.middle-btn4 {
            background: linear-gradient(90deg, rgba(110, 33, 79, 1) 0%, rgba(137, 29, 73, 1) 100%);
        }

        button {
            // background: linear-gradient(90deg, rgba(10, 49, 97, 1) 0%, rgba(179, 25, 66, 1) 100%);
            margin: 0 3px;
            border: 0;
            width: 140px;
            height: 51px;
            border-radius: 0;
        }
    }

    .card-box {
        border-radius: 10px;
        border: 1px solid #CBCBCB;
        background: #FFF;
        width: 100%;
        height: 100px;
        padding: 16px;
        vertical-align: middle;
        line-height: 65px;
        cursor: pointer;
    }

    .search-panle {
        h4 {
            color: #000;
            text-align: left;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-top: 25px;
            padding-bottom: 5px;
        }

        .search {
            position: relative;
        }

        .search input {
            height: 60px;
            text-indent: 25px;
            border-radius: 5px;
            border: 1px solid #DADADA;
            background: #FFF;
            padding-left: 30px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
       

        .search-icon {
            position: absolute;
            top: 18px;
            left: 12px;
        }

        .search input::placeholder {
            color: #C8C8C8;
        }

        .search input:focus {
            box-shadow: none;
            border: 2px solid #CACACA;
        }

        .search .fa-search {
            position: absolute;
            top: 20px;
            left: 10px;
        }

        .search button {
            border: 0;
            background: transparent;
        }
    }

    .banking-btn {
        border-radius: 10px;
        border: 1px solid #0A3361 !important;
        background: #0A3361 !important;
        height: 50px;
        padding: 14px 20px;
        color: #FFF;
        border: 0;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        display: inline-block;
        margin-bottom: 0px;
        border: 1px solid #CBCBCB;
    }

    a.banking-btn:hover {
        color: #f2f2f2;
    }

    .form-control {
        border-radius: 10px;
        border: 1px solid #D0D0D0;
        background: #FFF;
        height: 45px;
        color: #868686;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .otp-input input {
        width: 60px;
        height: 60px;
        text-align: center;
        display: inherit;
        margin: 0 auto;
    }

    .white-card-box {
        border-radius: 5px;
        background: #FFF;
        padding: 20px;
        text-align: left;

        .company-name {
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        span.blue-badge {
            border-radius: 5px;
            background: #E0EFFF;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: inline-block;
            padding: 5px 7px;
            margin-top: 10px;
        }

        .gradient-bar {
            width: 275px;
            height: 24px;
            border-radius: 25px;
            background: linear-gradient(90deg, #202E5D 0%, #AF1842 100%);
        }

        .fs-normal {
            color: #FFF;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            padding-top: 6px;
            padding-bottom: 6px;
        }

        .fs-normal8 {
            font-size: 8px !important;
            padding-top: 7px !important;
            padding-bottom: 7px !important;
        }

        .p-left {
            padding-left: 38px;
        }

        .circle-box {
            position: relative;
        }

        .circle-box span {
            background: #FFF;
            border-radius: 100%;
            width: 34px;
            height: 34px;
            text-align: center;
            display: inline-block;
            position: absolute;
            left: -5px;
            top: -3px;
        }
    }

    .aligned-btn {
        border-radius: 5px;
        background: #149908;
        width: 100%;
        height: 45px;
        margin: 25px 0;
    }
    .not-aligned-btn {
        border-radius: 5px;
        background: #B31942;
        width: 100%;
        height: 45px;
        margin: 25px 0;
    }

    .not-aligned-btn a {
        text-align: center;
        color: #FFF;
        padding: 10px;
        display: inline-block;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .accordion-box {
        border-radius: 10px;
        border: 1px solid #DADADA;
        background: #FFF;
        padding: 20px 15px;
        width: 100%;
    }
    .accordion-box2 {
        border-radius: 10px;
        border: 1px solid #7e204b;
        background: #FFF;
        padding: 20px 15px;
        width: 100%;
    }

    .company-represents {
        border-radius: 10px;
        background: #FFF8F8;
        width: 100%;
        height: 50px;
        margin: 25px 0;
        color: #B31942;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        padding: 15px;
    }

    .accordion .accordion-header .accordion-button {
        padding: 0;
    }

    .accordion-item {
        border: 0;
    }

    .accordion-title {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .min-w200 {
        max-width: 200px;
    }

    .accordion-button:not(.collapsed) {
        background: transparent !important;
        border: 0;
    }

    .accordion-button {
        box-shadow: none !important;
        outline: 0 !important;
        border: 0 !important;
    }

    .accordion-body {
        text-align: left;
        padding: 15px 0 0 0;
    }

    .accordion-body ul li {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding-bottom: 10px;
    }

    .accordion-body ul li:last-child {
        padding-bottom: 0;
    }

    .accordion-body ul {
        border-top: 1px solid #DADADA;
        padding-top: 15px;
    }

    .mx-250 {
        width: 250px;
    }

}

.search-panle {
    h4 {
        color: #000;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 25px;
        padding-bottom: 5px;
    }

    .search {
        position: relative;
    }

    .search input {
        height: 60px;
        text-indent: 25px;
        border-radius: 5px;
        border: 1px solid #CACACA;
        background: #FFF;
        padding-left: 30px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .search-icon {
        position: absolute;
        top: 18px;
        left: 18px;
    }

    .search input::placeholder {
        color: #C8C8C8;
    }

    .search input:focus {
        box-shadow: none;
        border: 2px solid #CACACA;
    }

    .search .fa-search {

        position: absolute;
        top: 20px;
        left: 10px;
    }

    .search button {
        border: 0;
        background: transparent;
    }
}

ul.search-list {
    margin: 0;
    padding: 0;
}

ul.search-list li {
    list-style-type: none;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    border-bottom: 1px solid #F7F4F4;
}

a.view-more-link {
    color: #083060;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: block;
    padding-top: 20px;
}

.modal-title.h4 {
    color: #1F1F1F;
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
}

.modal-content {
    border: 0 !important
}

.calendaricon {
    position: absolute;
    right: 25px;
    top: 10px;
    opacity: 0.5;
}

a.add-more-links {
    border-radius: 10px;
    border: 1px solid #0A3361 !important;
    background: #0A3361 !important;
    height: 50px;
    padding: 14px 20px;
    color: #FFF;
    border: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin: 0 auto;
    max-width: 500px;
    display: block;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper input {
    border-radius: 10px;
    border: 1px solid #D0D0D0;
    background: #FFF;
    height: 45px;
    color: #868686;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    padding: 10px;
}

.modal-header {
    padding: 20px;
}

label.cursor-pointer.font-14 {
    line-height: 22px;
}

// bank-recommendation page

.bank-recommendation{
    
    h5{
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 5px;
    }

    ul{
        margin:0;
        padding: 0;
    }

    ul li{
        // background: url('../images/check.png') left 5px no-repeat;
        background: url('../images/flame.png') left top no-repeat;
        list-style-type: none;
        font-size: 14px;
        font-weight: 400;
        color: #4F4F4F;
        line-height: 27px;
        padding-left: 35px;
        padding-bottom: 7px;
    }

    p.para{
        color: #545454 !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        margin-bottom: 0;
    }
}

.para{
    color: #545454 !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 0;
}

// responsive CSS

@media (max-width: 767px) {
   
    .navbar-brand img {
        max-width: 100%;
        margin: 0 auto;
    }

    .top-empty-space {
        padding-top: 100px;
    }

    .light-blue-card, .blue-card {
        padding: 20px;
        margin-bottom: 100px;

        h1 {
            font-size: 24px;
        }

        .btn-group {
            display: inherit;

            button {
                width: 100%;
                margin: 0 0 10px 0;
                border-radius: 25px !important;
            }
        }
    }

    .pty {
        padding: 0 !important;
    }

    .site-footer {
        position: fixed;
        bottom: 0;
    }

    .site-footer .copyright {
        font-size: 14px;
    }

    .min-heights {
        min-height: 100vh;
    }

    .p15 {
        padding: 15px !important;
    }

    .light-blue-card .min-w200, .blue-card .min-w200 {
        max-width: 170px;
        margin-right: 10px;
    }

    .ptb5 {
        padding-bottom: 9px !important;
    }

    .text-left-mb {
        text-align: left !important;
    }

    .white-card-box .company-name {
        padding-bottom: 15px;
    }

    .accordion-title {
        font-size: 12px !important;
    }

    .accordion-box,
    .accordion-body {
        padding: 15px 12px !important;
    }

    .accordion-body {
        padding: 15px 12px 0 12px !important;
    }

    .mx-250 {
        width: 100% !important;
    }

    .company-represents {
        height: auto !important;
    }

    .modal-title.h4 {
        font-size: 18px;
    }
    
    .mb25{
        margin-bottom: 25px;
    }

}

.accordion-button::after {
    margin-left: 5px !important;
}

.clearfix{
    clear: both;
    overflow: hidden;
}

.active-row{
    border-left: 3px solid #f34040;
}

.component-loader {
    // background: #ffffffd4;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // backdrop-filter: blur(2px);
    // z-index: 2;
    min-height: 200px;

    .loader-icon {
        width: 120px;
        height: 120px;
    }

    .loader {
        width: 80px;
        height: 80px;
        background-image: url('../images/ailoader.gif');
        background-size: contain;

    }

}

.gradient-text {
    background: linear-gradient(90deg, #202E5D 0%, #AF1842 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    // font-size: 24px;
}

.featureOptionsLoader{
    position: unset;
    background-color: #F5FAFF;
 }

 .loaderPosition{
    position: absolute !important;
 
 }


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .light-blue-card a.link-btn:hover , .blue-card a.link-btn:hover {
        color: #0A3361!important;
        border-radius: 10px;
        border: none!important;
        background: #FFF;
        box-shadow: 0px 4px 20px 0px rgba(10, 51, 97, 0.1);
    }
}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .light-blue-card a.link-btn:hover,   .blue-card a.link-btn:hover  {
        color: #0A3361!important;
        border-radius: 10px;
        border: none!important;
        background: #FFF;
        box-shadow: 0px 4px 20px 0px rgba(10, 51, 97, 0.1);
    }
    .light-blue-card, .blue-card  {
        border-radius: 20px;
        background: #F5FAFF;
        border: 1px solid #F3FAF3;
        filter: blur(0px);
        padding: 16px;
        margin-bottom: 60px;
    }
    .light-blue-card, .blue-card {
        padding: 16px!important;
    }
    .light-blue-card .btn-group button,   .blue-card .btn-group button {
        width: 128px;
    }
    
}